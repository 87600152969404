import { Component, OnInit } from '@angular/core';
import { IpadsService } from './ipads.service';

@Component({
  selector: 'app-ipads',
  templateUrl: './ipads.component.html',
  styleUrls: ['./ipads.component.css']
})
export class IpadsComponent implements OnInit {

  dolarArg = 0;
  ipads;
  configuracion;

  constructor(public ipad: IpadsService) {

    this.ipad.getJson('https://dolarapi.com/v1/dolares/blue').subscribe((res: any) => {
      this.dolarArg = parseFloat(res['venta']) +15;
    });

    this.ipad.getIpads('https://likeiphone.com.ar/api/traerIpads.php').subscribe((res: any) => {
      this.ipads = res;
      console.log(this.ipads);
    });

    this.ipad.getIpads('https://likeiphone.com.ar/api/traerConfiguracion.php').subscribe((res: any) => {
      this.configuracion = res;
    });

  }

  ngOnInit(): void {
  }

}
