import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accesorios',
  templateUrl: './accesorios.component.html',
  styleUrls: ['./accesorios.component.css']
})
export class AccesoriosComponent implements OnInit {

  dolarArg = 0;
  accesorios;
  configuracion;

  constructor(private http: HttpClient) {
    this.http.get('https://dolarapi.com/v1/dolares/blue').subscribe((res: any) => {
      this.dolarArg = parseFloat(res['venta']) + 15;
    });

    this.http.get('https://likeiphone.com.ar/api/traerAccesorios.php').subscribe((res: any) => {
      this.accesorios = res;
      console.log(this.accesorios);
    });

    this.http.get('https://likeiphone.com.ar/api/traerConfiguracion.php').subscribe((res: any) => {
      this.configuracion = res;
    });
  }

  ngOnInit(): void {
  }

}
