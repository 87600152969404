import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {

  pesos;
  resultado;
  dolarArg = 0;

  constructor(private http: HttpClient) {

  }

  getValorDolar() {
    this.http.get('https://dolarapi.com/v1/dolares/blue').subscribe((res: any) => {
      this.dolarArg = parseFloat(res['venta']) +10;
    });
  }

  guardarConfig() {
    this.http.post('https://likeiphone.com.ar/api/guardarOpciones.php', this.pesos).subscribe((result: any) => {
      this.resultado = result.res;
      if (this.resultado) {
        location.reload();
      }
    })
  }

  traerSesion() {
    this.http.get('https://likeiphone.com.ar/api/traerSesion.php').subscribe((acceso: any) => {
      if (acceso != 1) {
        window.location.href = './#/admin/acceso';
      }
    })
  }

  ngOnInit(): void {
    this.traerSesion();
    this.getValorDolar();
  }



}
