import { Component, OnInit } from '@angular/core';
import { UsadosIphoneService } from './usados-iphone.service';

@Component({
  selector: 'app-usados-iphone',
  templateUrl: './usados-iphone.component.html',
  styleUrls: ['./usados-iphone.component.css']
})
export class UsadosIphoneComponent implements OnInit {

  usados = [];
  usadosPalermo = [];
  usadosOlavarria = [];
  usadosPehuajo = [];
  usadosAyacucho = [];
  usadosPinamar = [];
  usadosBolivar = [];
  usadosTrenque = [];
  dolarArg = 0;
  configuracion = [];
  openbox = [];

  constructor(public celularesUsados: UsadosIphoneService) {

    this.celularesUsados.getJson('https://dolarapi.com/v1/dolares/blue').subscribe((res: any) => {
      this.dolarArg = parseFloat(res['venta']) + 15;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=la plata').subscribe((res: any) => {
      this.usados = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=olavarria').subscribe((res: any) => {
      this.usadosOlavarria = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=palermo').subscribe((res: any) => {
      this.usadosPalermo = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=pehuajo').subscribe((res: any) => {
      this.usadosPehuajo = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=ayacucho').subscribe((res: any) => {
      this.usadosAyacucho = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=pinamar').subscribe((res: any) => {
      this.usadosPinamar = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=bolivar').subscribe((res: any) => {
      this.usadosBolivar = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerCelularesUsadosPremium.php?ciudad=trenque').subscribe((res: any) => {
      this.usadosTrenque = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerConfiguracion.php').subscribe((res: any) => {
      this.configuracion = res;
    });

    this.celularesUsados.getUsados('https://likeiphone.com.ar/api/traerOpenBox.php').subscribe((res: any) => {
      this.openbox = res;
    });

  }

  replaceCommasDots(value: number): string {
    const formattedValue = value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue.replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',');
  }
  
  ngOnInit(): void {

  }

}
