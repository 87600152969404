import { Component, OnInit } from '@angular/core';
import { NuevoIphoneService } from './nuevo-iphone.service';


@Component({
  selector: 'app-nuevos-iphone',
  templateUrl: './nuevos-iphone.component.html',
  styleUrls: ['./nuevos-iphone.component.css']
})
export class NuevosIphoneComponent implements OnInit {

  dolarArg=0;
  nuevosCelulares;
  configuracion;

  constructor(public nuevoIphone:NuevoIphoneService) {

    this.nuevoIphone.getJson('https://dolarapi.com/v1/dolares/blue').subscribe((res:any) => {
      this.dolarArg=parseFloat(res['venta'])+15;
    });

    this.nuevoIphone.getCelulares('https://likeiphone.com.ar/api/traerCelularesNuevos.php').subscribe((res:any) => {
      this.nuevosCelulares=res;
      console.log(this.nuevosCelulares);
    });

    this.nuevoIphone.getCelulares('https://likeiphone.com.ar/api/traerConfiguracion.php').subscribe((res:any) => {
      this.configuracion=res;
    });
    
  }
  
  ngOnInit(): void {
    
  }


  




  
}
